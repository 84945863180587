.info-window-wrapper {
  border-radius: 8px;
  background-color: #fff;
  width: 140px;

  .info-window-header {
    padding: 5px;
    margin: 0;
    border-bottom: 1px solid #f0f0f0;
    font-weight: bold;
  }

  .info-window-content {
    p {
      margin: 3px;
    }

    .description {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .description-item {
      padding-left: 5px;

      &::before {
        content: '';
        position: relative;
        display: inline-block;
        display: inline-flex;
        top: -1px;
        left: -2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #666666;
      }
    }
  }
}