.alert-table-no-urgent {
  .ant-table-body {
    max-height: calc(100vh - 464px) !important;
  }
}

.alert-aggregate {
  .ant-pro-card-body {
    padding-top: 5px !important;
    padding-bottom: 0 !important;
  }

  label {
    width: 120px;
    padding: 0 12px;
    font-weight: bold;
  }
}

.ant-table-cell {
  white-space: nowrap;
  overflow: hidden;
}

.link-cell {
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .ant-typography {
    color: red !important;
  }
}

tr.selected-row td.ant-table-cell,
tr.selected-row td.ant-table-cell-row-hover {
  background-color: #f4ead5 !important;
}

div.alert-list-table .ant-pagination {
  display: none;
}