.lasted-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  background-color: #f0f2f5;

  p {
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;
    padding: 5px;
    background-color: #fff;
    border-left: 5px solid;

    &:last-child {
      margin-right: 0;
    }

    span {
      margin-right: 5px;

      &.alert-name {
        margin-right: 10px;
        margin-left: 5px;
        font-weight: bold;
      }
    }

    &.security-alert {
      border-color: red;

      span.anticon {
        color: red;
      }
    }

    &.shock-alert {
      border-color: #db6b22;

      span.anticon {
        color: #db6b22;
      }
    }

    &.multiple-alert {
      border-color: #f10fdb;

      span.anticon {
        color: #f10fdb;
      }
    }
  }

  /* Track */
  &::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #787878;
  }
}

.link-cell {
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
}