.buffer {
  height: 18px;

  .ant-row {
    align-items: center;
  }
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.cumulative-alert {
  width: 100%;
  background: white;
  border-color: red;
  border-top: 2px solid red;
  border-radius: 2px;
}

.security-page {
  padding-top: 10px !important;

  .ant-row {
    row-gap: 24px !important;
  }
}

.security-alert-icon {
  color: #c00;
  margin: 0 10px
}

.drawer {
  top: 0 !important;
  height: calc(100vh - 65px) !important
}

.ant-drawer-content-wrapper {
  top: auto !important;
}

.security-title-panel {
  font-size: large;
  font-weight: bold;

  span {
    margin-left: 0px;
  }
}

.security-drawer {
  .ant-divider {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .line-label:not(:first-child) {
    margin-top: 30px;
  }

  .line-label {
    font-weight: bold;
    font-size: 600;
  }
}