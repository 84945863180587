.search-form {
  .ant-card.search-form-group {
    background: none !important;
    border-radius: 5px;
    box-shadow: 0 4px 8px -3px;

    .ant-card-body {
      padding: 0 5px !important;
    }

    .ant-form-item {
      margin-bottom: 5px !important;

      .ant-form-item-label {
        font-weight: 600;
      }
    }
  }

  .ant-select {
    width: 100%;
  }

  .form-search-action {
    display: flex;
    justify-content: end;
    align-items: end;

    .btn-search {
      height: 40px;
      border-radius: 4px;
      color: #fff;
      border-color: #cc0000;
      background: #cc0000;

      &:hover {
        border-color: #d92923;
        background: #d92923;
      }
    }
  }
}