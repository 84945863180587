@font-face {
  font-family: "HAL-Regular", sans-serif;
  src: local("HAL-Regular"),
    url(./asset/fonts/OpenSans-Light.ttf) format("truetype");
}
@font-face {
  font-family: "HAL-Medium", sans-serif;
  src: local("HAL-Medium"),
    url(./asset/fonts/OpenSans-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "HAL-Light", sans-serif;
  src: local("HAL-Light"),
    url(./asset/fonts/OpenSans-Light.ttf) format("truetype");
}

body {
  background: #f0f2f5 !important;
  margin: 0;
  font-family: "HAL-Light", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

.ira-main {
  margin: 0 24px;
}

a {
  color: #c00 !important;
}

.mt-10 {
  margin-top: 10px;
}

.ant-input,
.ant-select-selector,
.ant-picker,
.ant-pagination-item {
  border-radius: 2px !important;
}

// Custom table
.ant-table-wrapper {
  .ant-table-thead {
    th {
      border-radius: 2px !important;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: 2px 0 0 0;
  }
}

// Custom table title
.table-title {
  margin-top: 1px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

// Custom iframe
.iframe-chart {
  border: none;
}

.gm-ui-hover-effect {
  display: none !important;
}

tr.selected-row,
tr.selected-row td.ant-table-cell,
tr.selected-row td.ant-table-cell-row-hover {
  a span {
    font-weight: bold;
  }
  background-color: lavender !important;
}
