.legend-desctiption {
  width: 18px;
  height: 18px;
  background-color: #26c32d;
  border-radius: 2px;
}

.map-container {
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}

.map-legend {
  position: absolute;
  top: 10px;
  left: 22px;
  z-index: 999;

  .ant-row {
    margin-bottom: 5px;
  }

  button {
    background-color: #fff;
    border: 1px solid #c2bfba;

    &:hover {
      background-color: #fff;
      border: 1px solid #cc0000;
    }
  }

  span.anticon {
    font-size: 20px;
  }

  .map-legend-body {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.4);

    .shiment-description {
      label {
        font-weight: bold;
      }
    }

    .square-box {
      @extend .legend-desctiption;
    }

    .triangle-box {
      width: 0;
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 18px solid #26c32d;
    }

    .circle-box {
      @extend .legend-desctiption;
      border-radius: 50%;
    }
  }

  .single-legend {
    background-color: rgba(255, 255, 255, 0.4);
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important;

    div.icon {
      padding-left: 0 !important;
      padding-right: 0 !important;
      max-width: unset;
      height: 24px;
    }

    div.text {
      padding-left: 0 !important;
      padding-right: 5px !important;
    }
  }
}