fieldset {
  margin: auto !important;
  padding: 0 10px 10px 10px !important;
  background: #fff;
  border: 1px solid #949597 !important;

  legend {
    width: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    color: #cd0505 !important;
    border: none !important;
  }
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 !important;
  padding: 5px 16px;
}

.ant-tabs .ant-tabs-tab .anticon {
  margin-right: 5px;
}

.ant-tabs-tab-btn img {
  margin-right: 5px;
  color: "#42BCE0";
}

.ant-tabs-nav {
  margin: 0 0 5px 0 !important;
}

.ant-tabs-tab:nth-child(1) {
  border-top: 2px solid #db6b22 !important;
}

.ant-tabs-tab:nth-child(2) {
  border-top: 2px solid #22b1db !important;
}

.ant-tabs-tab:nth-child(3) {
  border-top: 2px solid red !important;
}

.ant-tabs-tab-active {
  background-color: #fff !important;

  .ant-tabs-tab-btn {
    color: #c00 !important;
  }
}

.urgent-message {
  margin-top: 10px !important;

  .ant-alert {
    border-radius: 2px !important;
  }
}

.ant-pagination {
  margin-top: 3px !important;
  justify-content: end;
  display: flex;
}

.landing-page {
  margin-top: 8px;

  .ant-row {
    row-gap: 24px !important;
  }
}

.filter-field {
  .ant-row {
    row-gap: 0px !important;
  }
}

.dipslay-time-form {
  .ant-select {
    width: 150px;
  }

  .ant-form-item-label {
    font-weight: 600;
  }
}