.ira-header {
  background-color: #f2f2f2;
  border-top: 5px solid #c00;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  height: 50px;
  padding: 0 24px;

  .left-content {
    display: flex;
    align-items: center;

    .ira-logo {
      margin-right: 20px;

      h1 {
        margin: 0;
        padding: 0;
        color: #000 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        font-family: "HAL-Medium", sans-serif;
      }
    }
  }

  .right-content {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;

    .current-time {
      margin-right: 10px;
    }

    span {
      font-weight: 600;
      cursor: pointer;
    }
  }

  .ant-row {
    height: 100%;
  }
}