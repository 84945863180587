.shock-header-table {
  border-top: 2px solid #DB6B22;
}

.shock-title-header-table {
  font-size: 14px;
  font-weight: 600;
}

.shock-table {
  .ant-card-body {
    height: 684px;
    padding: 5px 12px 0 12px !important;
  }

  .ant-table {
    margin-top: 12px;
  }

  .ant-pagination {
    margin-top: 10px !important;
  }

  .ant-table-content {
    max-height: calc(100vh - 315px);
    overflow: auto;
  }
}

.shock-aggregate {
  label {
    width: 120px;
    padding: 0 12px;
    font-weight: bold;
  }
}