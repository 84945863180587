.shock-page {
  margin-top: 10px !important;

  .ant-row {
    row-gap: 5px !important;
  }
}

.cumulative-shock {
  width: 25%;
  background: white;
  border-color: #DB6B22;
  border-top: 2px solid #DB6B22;
  border-radius: 2px;
}

.shock-title-panel {
  font-size: large;
  font-weight: bold;

  span {
    margin-left: 0px;
  }
}

.shock-alert-icon {
  margin: 0 10px;
}

#timeline-container {
  height: 189px;
  margin-top: 28px;
}

#enegry-container {
  height: 190px;
}

.chart {
  .quicksight-embedding-iframe {
    display: inherit;
  }
}

.quicksight-embedding-iframe {
  display: none;
}

.shock-drawer {
  .ant-divider {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .line-label:not(:first-child) {
    margin-top: 30px;
  }

  .line-label {
    font-weight: bold;
    font-size: 600;
  }
}