.shipment-info-window-wrapper {
  border: none !important;
  background-color: #fff;
  width: 165px;

  .info-window-header {
    padding: 5px;
    margin: 0;
    border-bottom: 1px solid #f0f0f0;
    font-weight: bold;
  }

  .mr-17 {
    margin-right: 17px;
  }

  .info-window-content {
    padding: 0 5px 5px 5px;

    .info-window-content-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 3px;
      position: relative;
      margin: 5px 0 0 0;
      background-color: #f0f0f0;
      border-radius: 2px;
      border: 1px solid #f0f0f0;

      &:hover {
        background-color: #dbd9d9;
      }

      .anticon-right {
        position: absolute;
        right: 5px;
        font-weight: bold;
      }

      label {
        cursor: pointer;
      }
    }

    a {
      color: #000000 !important;
      font-weight: bold;
    }
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .security-icon {
    margin-right: 7px
  }

  .shock-icon {
    margin-right: 7px
  }
}

.gm-style {
  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw-c {
    padding: 0 !important;
  }
}