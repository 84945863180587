.security-header-table {
  border-top: 2px solid red;
}

.security-title-header-table {
  font-size: 14px;
  font-weight: 600;
}

.security-table {
  .ant-card-body {
    padding: 5px 12px 0 12px !important;
  }

  .ant-table {
    margin-top: 12px;
  }

  .ant-pagination {
    margin-top: 10px !important;
  }

  .ant-table-content {
    max-height: 655px;
    overflow: auto;
  }
}

.security-table-aggregate {
  label {
    width: 120px;
    padding: 0 12px;
    font-weight: bold;
  }
}